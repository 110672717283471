<div class="auth-page-container">
  <mat-card appearance="outlined" class="login-header top-header">
    <mat-card-content class="bold">
      <h2>SchoolMint Hero</h2>
      <h3>School User Login</h3>
      Log in here if you are a school-based Hero user (teacher, administrator,
      staff member). Contact your school admin for your account information.
    </mat-card-content>
  </mat-card>

  <!-- Container -->
  <mat-card appearance="outlined" class="login-container">
    <!-- Local Login Form -->
    <mat-card-content class="local-login-form">
      <!-- Email -->
      <mat-form-field class="full-width-input">
        <mat-label for="email"> Email Address </mat-label>
        <input
          matInput
          id="email"
          class="form-control"
          type="email"
          placeholder="Enter Email"
          autocomplete="email"
          [(ngModel)]="email"
          (keydown.enter)="login()" />
      </mat-form-field>

      <!-- Password -->
      <mat-form-field class="full-width-input">
        <mat-label for="password"> Password </mat-label>
        <input
          matInput
          id="password"
          class="form-control"
          [type]="hidePassword ? 'password' : 'text'"
          placeholder="Password"
          [(ngModel)]="password"
          (keydown.enter)="login()" />
        <fa-icon
          matSuffix
          [icon]="hidePassword ? 'eye' : 'eye-slash'"
          (click)="hidePassword = !hidePassword"></fa-icon>
      </mat-form-field>

      <!-- Actions -->
      <div class="local-login-actions">
        <!-- Sign In Button -->
        <button
          mat-flat-button
          id="btnLoginLocal"
          type="button"
          color="primary"
          (click)="login()">
          Sign-In
        </button>

        <!-- Forgot Password Link -->
        <button
          mat-stroked-button
          id="btnForgotPassword"
          color="primary"
          type="button"
          class="standard-button"
          (click)="resetPassword()">
          Forgot Password?
        </button>
      </div>
      <mat-divider></mat-divider>
      <!-- Other Login Buttons -->
      <div class="other-login-buttons">
        <!-- Login With Google IDENTITY SERVER -->
        <!-- (click)="loginWithGoogle()" -->
        <button mat-flat-button color="primary" class="standard-button">
          <div class="button-content">
            <fa-icon [icon]="['fab', 'google']" size="2x"></fa-icon>
            <a [href]="googleLoginUrl">Login with Google</a>
          </div>
        </button>
        <button
          mat-flat-button
          color="primary"
          href="javascript:void(0)"
          class="standard-button">
          <div class="button-content">
            <fa-icon [icon]="['fab', 'microsoft']" size="2x"></fa-icon>
            <a [href]="azureLoginUrl">Login with Microsoft</a>
          </div>
        </button>
      </div>
      <a
        href="https://schoolminthero.zendesk.com/hc/en-us/articles/28847632389275-Need-Login-Support"
        class="help-text"
        >Need Login Support?</a
      >
    </mat-card-content>
  </mat-card>
</div>
