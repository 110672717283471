import { Injectable } from '@angular/core';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { BaseService } from '../../shared/base-classes/base.service';

import { IIncidentCreateEditResolverData } from './incident-create-edit/incident-create-edit-resolver-data.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { IIncidentListResolverData } from './incident-list/incident-list-models/incident-list-resolver.interface';
import { IReferralEditResolverData } from './referral-edit/referral-edit-resolver-data.interface';
import {
  DefaultOptionCategoriesIncidentEnum,
  DispositionCreateDto,
  DispositionResponseDto,
  DispositionUpdateDto,
  HeroDispositionUpdateDto,
  IDisplayData,
  IncidentListResponseDto,
  IncidentOptionsResponseDto,
  IncidentResponseDto,
  IncidentStatusEnum,
  IncidentUpdateDto,
  OptionsCreateDto,
  OptionsUpdateDto,
  PaginationResultsDto,
  ReferralCreateDto,
  ReferralDetailResponseDto,
  ReferralReviewUpdateDto,
  ReferralsOptionsResponseDto,
  ReferralUpdateDto,
  RelationDto
} from '@whetstoneeducation/hero-common';
import { IIncidentListFilter } from './incident-list/incident-list-models/incident-list-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class AppIncidentService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public async getIncidentConfig() {
    return this.appClientDataService.execute<IncidentOptionsResponseDto>(
      this.GET_ROUTES.INCIDENT_OPTIONS
    );
  }

  public createOption(option: OptionsCreateDto) {
    return this.appClientDataService.execute(this.POST_ROUTES.CREATE_OPTION, {
      method: this.METHOD.POST,
      body: option
    });
  }

  public updateOption(id: number, option: OptionsUpdateDto) {
    return this.appClientDataService.execute(this.PATCH_ROUTES.UPDATE_OPTION, {
      method: this.METHOD.PATCH,
      body: option,
      pathParams: { id }
    });
  }

  public createIncident() {
    return this.appClientDataService.execute<IncidentResponseDto>(
      this.POST_ROUTES.CREATE_INCIDENT,
      {
        method: this.METHOD.POST
      }
    );
  }

  public updateIncident(id: number, updateDto: IncidentUpdateDto) {
    return this.appClientDataService.execute(
      this.PATCH_ROUTES.UPDATE_INCIDENT,
      {
        method: this.METHOD.PATCH,
        body: updateDto,
        pathParams: { id }
      }
    );
  }

  public getIncident(id: number) {
    return this.appClientDataService.execute<IncidentResponseDto>(
      this.GET_ROUTES.INCIDENT_BY_ID,
      {
        method: this.METHOD.GET,
        pathParams: { id }
      }
    );
  }

  public getIncidents(filter: IIncidentListFilter) {
    return this.appClientDataService.execute<
      PaginationResultsDto<IncidentListResponseDto>
    >(this.GET_ROUTES.INCIDENT_LIST, {
      method: this.METHOD.GET,
      queryParams: {
        ...(filter.search && { search: filter.search }),
        ...(filter.incidentTypeId && { incidentTypeId: filter.incidentTypeId }),
        ...(filter.startDate && { startDate: filter.startDate }),
        ...(filter.endDate && { endDate: filter.endDate }),
        ...(filter.status && { status: filter.status }),
        ...(filter.reporterId && { reporterId: filter.reporterId }),
        schoolId: filter.schoolId,
        ...filter.tableFilters
      }
    });
  }

  public async getOptionsByCategory(category: string) {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.OPTIONS_BY_CATEGORY,
      {
        method: this.METHOD.GET,
        pathParams: { category }
      }
    );
  }

  public async getIncidentCreateEditResolverData(
    id?: number
  ): Promise<IIncidentCreateEditResolverData> {
    const [incidentTypes, incidentPlaces, incident] = await Promise.all([
      this.getOptionsByCategory(
        DefaultOptionCategoriesIncidentEnum.INCIDENT_TYPES
      ),
      this.getOptionsByCategory(
        DefaultOptionCategoriesIncidentEnum.INCIDENT_PLACES
      ),
      id ? this.getIncident(id) : this.createIncident()
    ]);

    return {
      incidentTypes,
      incidentPlaces,
      incident
    };
  }

  public async getUserOptions(schoolId: number): Promise<IDisplayData[]> {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.USER_DROPDOWN,
      {
        method: this.METHOD.GET,
        pathParams: {
          schoolId
        }
      }
    );
  }

  async getIncidentListResolverData(): Promise<IIncidentListResolverData> {
    const schoolId = this.StorageManager.getCurrentSchoolId();
    const defaultFilters = {
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.INCIDENT_LIST
      ),
      schoolId
    };

    const [incidents, incidentTypeOptions, userOptions] = await Promise.all([
      this.getIncidents(defaultFilters),
      this.getOptionsByCategory(
        DefaultOptionCategoriesIncidentEnum.INCIDENT_TYPES
      ),
      this.getUserOptions(schoolId)
    ]);

    return {
      defaultFilters,
      incidents,
      incidentTypeOptions,
      userOptions
    };
  }

  public createReferral(dto: ReferralCreateDto) {
    return this.appClientDataService.execute(this.POST_ROUTES.CREATE_REFERRAL, {
      method: this.METHOD.POST,
      body: dto
    });
  }

  public async getReferralOptions(): Promise<ReferralsOptionsResponseDto> {
    return this.appClientDataService.execute<ReferralsOptionsResponseDto>(
      this.GET_ROUTES.REFERRAL_OPTIONS,
      {
        method: this.METHOD.GET
      }
    );
  }

  public async getReferral(
    referralId: number
  ): Promise<ReferralDetailResponseDto> {
    return this.appClientDataService.execute<ReferralDetailResponseDto>(
      this.GET_ROUTES.REFERRAL_BY_ID,
      {
        method: this.METHOD.GET,
        pathParams: { id: referralId }
      }
    );
  }

  public async updateReferral(
    referralId: number,
    updateDto: ReferralUpdateDto
  ) {
    return this.appClientDataService.execute(
      this.PATCH_ROUTES.UPDATE_REFERRAL,
      {
        method: this.METHOD.PATCH,
        body: updateDto,
        pathParams: { id: referralId }
      }
    );
  }

  public async updateDispositions(
    referralId: number,
    updateDispositionDtos: DispositionUpdateDto[]
  ) {
    return this.appClientDataService.execute(
      this.PATCH_ROUTES.UPDATE_DISPOSITIONS,
      {
        method: this.METHOD.PATCH,
        body: updateDispositionDtos,
        pathParams: { id: referralId }
      }
    );
  }

  public async updateHeroDisposition(
    referralId: number,
    updateHeroDispositionDto: HeroDispositionUpdateDto
  ) {
    return this.appClientDataService.execute(
      this.PATCH_ROUTES.UPDATE_HERO_DISPOSITION,
      {
        method: this.METHOD.PATCH,
        body: updateHeroDispositionDto,
        pathParams: { id: referralId }
      }
    );
  }

  public async getEditReferralResolverData(
    referralId: number
  ): Promise<IReferralEditResolverData> {
    const [referral, referralOptions] = await Promise.all([
      this.getReferral(referralId),
      this.getReferralOptions()
    ]);

    let incidentBehaviorCodeOptions: IDisplayData[];
    let behaviorCodeReactionOptions: IDisplayData[];

    if (
      [IncidentStatusEnum.PENDING, IncidentStatusEnum.REJECTED].includes(
        referral.status as IncidentStatusEnum
      )
    ) {
      incidentBehaviorCodeOptions =
        await this.getIncidentBehaviorCodeTypesWithBehaviorCodes();
      if (referral.heroDisposition.behaviorCode) {
        behaviorCodeReactionOptions = await this.getReactionOptions(
          referral.heroDisposition.behaviorCode.id,
          referral.student.id
        ).then((response) =>
          response.map((reaction) => ({
            value: reaction.id,
            display: reaction.name
          }))
        );
      }
    }
    return {
      referral,
      referralOptions,
      incidentBehaviorCodeOptions,
      behaviorCodeReactionOptions
    };
  }
  public async deleteReferral(referralId: number) {
    return this.appClientDataService.execute(
      this.DELETE_ROUTES.DELETE_REFERRAL,
      {
        method: this.METHOD.DELETE,
        pathParams: { id: referralId }
      }
    );
  }

  public async createDisposition(dto: DispositionCreateDto) {
    return this.appClientDataService.execute<DispositionResponseDto>(
      this.POST_ROUTES.CREATE_DISPOSITION,
      {
        method: this.METHOD.POST,
        body: dto,
        pathParams: { id: dto.referralId }
      }
    );
  }

  public async deleteDisposition(referralId: number, dispositionId: number) {
    return this.appClientDataService.execute<DispositionResponseDto>(
      this.DELETE_ROUTES.DELETE_DISPOSITION,
      {
        method: this.METHOD.DELETE,
        pathParams: { referralId, dispositionId }
      }
    );
  }

  public async getIncidentBehaviorCodeTypesWithBehaviorCodes() {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.BEHAVIOR_CODES_LIST_BY_INCIDENT_BEHAVIOR_CODE_TYPE,
      {
        method: this.METHOD.GET,
        pathParams: { name: 'Incident' }
      }
    );
  }

  public async getBehaviorCodesByType(
    behaviorCodeTypeId: number
  ): Promise<RelationDto[]> {
    return this.appClientDataService.execute<RelationDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODES_LIST_BY_INCIDENT_BEHAVIOR_CODE_TYPE,
      {
        pathParams: {
          behaviorCodeTypeId
        }
      }
    );
  }

  public async getReactionOptions(
    behaviorCodeId: number,
    studentId: number
  ): Promise<RelationDto[]> {
    return this.appClientDataService.execute<RelationDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_NEXT_POSSIBLE_REACTION_OPTIONS,
      {
        queryParams: {
          behaviorCodeId,
          studentId
        }
      }
    );
  }
}
