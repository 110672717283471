<form [formGroup]="form" class="hero-disposition-container">
  <mat-form-field class="full-width-input">
    <mat-label>Behavior Code</mat-label>
    <mat-select formControlName="behaviorCodeId">
      <mat-option *ngFor="let option of behaviorCodeOptions" [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="behaviorCodeReactionOptions && behaviorCodeReactionOptions.length" class="full-width-input">
    <mat-label>Reaction</mat-label>
    <mat-select formControlName="behaviorCodeReactionId">
      <mat-option *ngFor="let option of behaviorCodeReactionOptions" [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="heroDisposition.behaviorCodeEntry">
    <mat-label>Action Date</mat-label>
    <input disabled="true" matInput placeholder="Grade" [value]="heroDisposition?.behaviorCodeEntry.name || 'no date'" />
  </mat-form-field>
</form>
