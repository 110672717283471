import { Component, Input } from '@angular/core';
import {
  HeroDispositionResponseDto,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hero-disposition-edit',
  templateUrl: './hero-disposition-edit.component.html',
  styleUrls: ['./hero-disposition-edit.component.scss']
})
export class HeroDispositionEditComponent {
  @Input() heroDisposition: HeroDispositionResponseDto;
  @Input() form: FormGroup;
  @Input() behaviorCodeOptions: IDisplayData[];
  @Input() behaviorCodeReactionOptions: IDisplayData[];

  constructor() {}
}
