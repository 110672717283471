"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsName = void 0;
var SettingsName;
(function (SettingsName) {
    SettingsName["TIMEZONE"] = "timezone";
    SettingsName["COURSE_SCHEDULE_IMPORT_SEQUENCE"] = "course_schedule_import_sequence";
    SettingsName["STUDENT_IMPORT_SEQUENCE"] = "student_import_sequence";
    SettingsName["INCIDENT"] = "incident";
    SettingsName["EMAIL_GUARDIAN_INVITE"] = "email_guardian_invite";
    SettingsName["GUARDIANS_MASS_ACTIVATED"] = "guardians_mass_activated";
    SettingsName["EVENT"] = "event";
})(SettingsName || (exports.SettingsName = SettingsName = {}));
