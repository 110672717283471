import { Component, Input, ViewChild } from '@angular/core';
import {
  SettingsName,
  StudentProfileResponseDto,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { PROFILE_TABS } from '../student-profile-tabs.enum';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-student-info-tabs',
  templateUrl: './student-info-tabs.template.html',
  styleUrls: ['./student-info-tabs.scss']
})
export class StudentInfoTabsComponent extends BaseComponent {
  @Input()
  public profile: StudentProfileResponseDto;

  public isStudent: boolean = false;

  @ViewChild('profileTabs', { static: false })
  public profileTabs: MatTabGroup;

  constructor() {
    super();
    this.isStudent = this.StorageManager.isUserType([
      UserTypeEnum.STUDENT,
      UserTypeEnum.GUARDIAN
    ]);
  }

  public changeTab(tabIndex: PROFILE_TABS) {
    const tabGroup = this.profileTabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;

    tabGroup.selectedIndex = tabIndex;
  }

  protected readonly SettingsName = SettingsName;
}
