import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../shared/base-classes/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppIncidentService } from '../../../incident.service';
import { ReferralDetailResponseDto, ReferralUpdateDto } from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';

@Component({
  selector: 'app-referral-reject-modal',
  templateUrl: './referral-reject-modal.component.html',
  styleUrls: ['./referral-reject-modal.component.scss']
})
export class ReferralRejectModalComponent
  extends BaseComponent
  implements OnInit
{
  public rejectForm: FormGroup;
  public isReview: boolean;
  public referral: ReferralDetailResponseDto;

  constructor(
    public incidentService: AppIncidentService,
    public toastService: AppToastManagerService,
    public dialogRef: MatDialogRef<ReferralRejectModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isReview: boolean,
      referralId: number
    },
    public formBuilder: FormBuilder,
  ) {
    super();
  }

  async ngOnInit() {

    this.isLoading = true;

    this.referral = await this.incidentService.getReferral(this.data.referralId);
    this.isReview = this.data.isReview;
  
    const initialNote = this.isReview ? '' : this.referral.rejectedNotes;
  
    this.rejectForm = this.formBuilder.group({
      rejectedNotes: [initialNote, Validators.required]
    });
  
    this.isLoading = false;

  }

  onCancel() {
    this.dialogRef.close(false);
  }

  async onSubmit() {
    if (this.rejectForm.valid) {
      const rejectedNotes = this.rejectForm.get('rejectedNotes').value;
      
      await this.changeRejectNote(rejectedNotes);

      this.dialogRef.close(true);
    }
  }

  public async changeRejectNote(rejectedNotes: string) {
    this.isLoading = true;
    try {
      await this.incidentService.updateReferral(
        this.data.referralId,
        new ReferralUpdateDto({ rejectedNotes })
      );
    } catch (e) {
      this.toastService.error('Error rejecting referral message');
    } finally {
      this.isLoading = false;
    }
  }
}