"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeroDispositionUpdateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class HeroDispositionUpdateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return ['behaviorCodeId', 'behaviorCodeReactionId'];
    }
    behaviorCodeId;
    behaviorCodeReactionId;
}
exports.HeroDispositionUpdateDto = HeroDispositionUpdateDto;
