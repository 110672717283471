import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppAuthModule } from './pages/auth/auth.module';
import { AppBehaviorCodeEntryModule } from './pages/behavior-code-entry/behavior-code-entry.module';
import { AppBehaviorCodesModule } from './pages/behavior-codes/behavior-codes.module';
import { AppBellSchedulesModule } from './pages/bell-schedules/bell-schedules.module';
import { AppCalendarModule } from './pages/calendar/calendar.module';
import { AppColorProgressBarModule } from './shared/color-progress-bar/color-progress-bar.module';
import { AppComponent } from './app.component';
import { AppConfirmationDialogModule } from './shared/confirmation-dialog/confirmation-dialog.module';
import { AppDataInitService } from './app-data-init.service';
import { AppDashboardModule } from './pages/dashboards/dashboard.module';
import { AppErrorBoundaryModule } from './shared/error-boundary/error-boundary.module';
import { AppHomeModule } from './pages/home/home.module';
import { AppInterventionsModule } from './pages/interventions/interventions.module';
import { AppMultipleSelectModule } from './shared/dropdowns/multi-select/multiple-select.module';
import { AppMySettingsModule } from './pages/my-settings/my-settings.module';
import { AppNavbarModule } from './shared/navbar/navbar.module';
import { AppPageHeaderModule } from './shared/page-header/page-header.module';
import { AppReactionsModule } from './pages/reactions/reactions.module';
import { AppRolesModule } from './pages/roles/roles.module';
import { AppRoutingModule } from './app-routing.module';
import { AppSaveBarModule } from './shared/save-bar/save-bar.module';
import { AppSchoolGroupsModule } from './pages/school-groups/school-groups.module';
import { AppSchoolsModule } from './pages/schools/schools.module';
import { AppImportModule } from './pages/imports/import.module';
import { AppExportModule } from './pages/exports/export.module';
import { AppStudentsModule } from './pages/students/students.module';
import { AppUsersModule } from './pages/users/users.module';
import { AuthGuard } from './route-guards/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { EnvServiceProvider } from './env.service.provider';
import {
  FaIconLibrary,
  FontAwesomeModule
} from '@fortawesome/angular-fontawesome';
import { FullCalendarModule } from '@fullcalendar/angular';
import { far, fas, fass, fab } from '@awesome.me/kit-e17f36da89/icons/modules';
import { HttpClientModule } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ReactiveFormsModule } from '@angular/forms';
import { AppFileUploadModule } from './shared/file-upload/file-upload.module';
import { AppMaterialModule } from './app-material.module';
import { AppReportsModule } from './pages/reports/reports.module';
import { AppComplianceModule } from './pages/compliance/compliance.module';
import { AppNotificationModule } from './pages/notifications/notification.module';
import { AppStudentGroupsModule } from './pages/student-groups/student-groups.module';
import { AppUserlaneModule } from './shared/support-buttons/support-buttons.module';
import { AppStudentCardModule } from './shared/student-card/student-card.module';
import { AppTeacherRostersModule } from './pages/teacher-rosters/teacher-rosters.module';
import { AppBannersModule } from './shared/banners/banners.module';
import { AppTablesModule } from './shared/tables/tables.module';
import { AppInputsModule } from './shared/inputs/inputs.module';
import { AppStudentHeaderInfoModule } from './shared/student-header-info/student-header-info.module';
import { AppPointsBubbleModule } from './shared/points-bubble/points-bubble.module';
import { AppBackButtonModalModule } from './shared/back-button-modal/back-button-modal.module';
import { AppImportsExportsModule } from './pages/imports-exports/last-imports-exports.module';
import { AppStudentProfilePhotoModule } from './shared/student-profile-photo/student-profile-photo.module';
import { SchoolGroupGuard } from './route-guards/school-group.guard';
import { AppCustomRostersModule } from './pages/custom-rosters/custom-rosters.module';
import { AppIncidentModule } from './pages/incident/incident.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppAuthModule,
    AppBehaviorCodeEntryModule,
    AppBehaviorCodesModule,
    AppBellSchedulesModule,
    AppCalendarModule,
    AppColorProgressBarModule,
    AppFileUploadModule,
    AppConfirmationDialogModule,
    AppDashboardModule,
    AppErrorBoundaryModule,
    AppHomeModule,
    AppInterventionsModule,
    AppMultipleSelectModule,
    AppMySettingsModule,
    AppNavbarModule,
    AppPageHeaderModule,
    AppReactionsModule,
    AppReportsModule,
    AppRolesModule,
    AppRoutingModule,
    AppSaveBarModule,
    AppSchoolGroupsModule,
    AppSchoolsModule,
    AppImportModule,
    AppStudentsModule,
    AppUsersModule,
    AppNotificationModule,
    BrowserModule,
    FontAwesomeModule,
    FullCalendarModule,
    HttpClientModule,
    MatDialogModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    AppComplianceModule,
    AppStudentGroupsModule,
    AppUserlaneModule,
    AppStudentCardModule,
    AppTeacherRostersModule,
    AppBannersModule,
    AppTablesModule,
    AppInputsModule,
    AppStudentHeaderInfoModule,
    AppStudentProfilePhotoModule,
    AppPointsBubbleModule,
    AppExportModule,
    AppBackButtonModalModule,
    AppImportsExportsModule,
    AppCustomRostersModule,
    AppIncidentModule
  ],
  providers: [
    EnvServiceProvider,
    AuthGuard,
    SchoolGroupGuard,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: 'always' }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appDataInitService: AppDataInitService) => () =>
        appDataInitService.loadInitialAppData(),
      multi: true,
      deps: [AppDataInitService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fass, fab);
  }
}
