<mat-tab-group
  class="student-profile-tabs"
  aria-label="Student Tabs"
  #profileTabs>
  <mat-tab *ngIf="isStudent" label="DASHBOARD" aria-label="Dashboard">
    <app-student-dashboard
      [studentId]="profile.studentDetail.id"
      (changeTab)="changeTab($event)">
    </app-student-dashboard>
  </mat-tab>
  <mat-tab label="INFORMATION" aria-label="Information">
    <app-student-profile-info [profile]="profile"> </app-student-profile-info>
    <div class="student-profile-metrics">
      <app-student-profile-metrics [profile]="profile">
      </app-student-profile-metrics>
    </div>
  </mat-tab>
  <mat-tab label="SCHEDULE" aria-label="Schedule">
    <app-student-schedules
      [studentId]="profile.studentDetail.id"></app-student-schedules>
  </mat-tab>
  <mat-tab label="GUARDIANS" aria-label="Guardians">
    <app-student-guardian-contacts [profile]="profile" [isStudent]="isStudent">
    </app-student-guardian-contacts>
  </mat-tab>
  <mat-tab label="BEHAVIOR HISTORY" aria-label="Behavior History">
    <ng-container *ngIf="profile.studentDetail.id">
      <app-behavior-history [studentId]="profile.studentDetail.id">
      </app-behavior-history>
    </ng-container>
  </mat-tab>
  <mat-tab *ngIf="hasSetting(SettingsName.INCIDENT)" label="INCIDENTS HISTORY" aria-label="Incidents History">
    <app-incident-history [studentId]="profile.studentDetail.id">
    </app-incident-history>
  </mat-tab>
  <mat-tab label="ACCOUNTS" aria-label="Accounts">
    <ng-container>
      <app-student-account [profile]="profile"> </app-student-account>
    </ng-container>
  </mat-tab>
</mat-tab-group>
