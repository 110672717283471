<div class="mat-dialog-header">
  <div class="mat-dialog-title">Add Students</div>
  <fa-icon (click)="handleClose()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content *ngIf="!isLoading">
  <div class="first-row">
    <div class="custom-roster-name">Add Students To Incident</div>
    <div class="students-added">
      {{ participantsAdded.length }} students added
    </div>
  </div>
  <div class="filter-row">
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>First Name</mat-label>
      <input
        data-test-id="FIRST_NAME_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.firstName"
        (ngModelChange)="updateFilters(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>Last Name</mat-label>
      <input
        data-test-id="LAST_NAME_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.lastName"
        (ngModelChange)="updateFilters(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>Grade</mat-label>
      <input
        data-test-id="GRADE_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.grade"
        (ngModelChange)="updateFilters(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>ID Number</mat-label>
      <input
        data-test-id="STUDENT_EXT_ID_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.studentExtId"
        (ngModelChange)="updateFilters(filters)" />
    </mat-form-field>
  </div>
  <form [formGroup]="form">
    <div *ngIf="students && !isLoading" class="mat-table participant-list">
      <app-pagination
        [tableFilters]="filters.tableFilters"
        (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
      <mat-table [dataSource]="students">
        <ng-container matColumnDef="{{ columns.STUDENT_NAME }}">
          <mat-header-cell *matHeaderCellDef>
            {{ columns.STUDENT_NAME }}
          </mat-header-cell>
          <mat-cell *matCellDef="let student">
            {{ student.firstName }} {{ student.lastName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{ columns.STUDENT_ID }}">
          <mat-header-cell *matHeaderCellDef>
            {{ columns.STUDENT_ID }}
          </mat-header-cell>
          <mat-cell *matCellDef="let student">
            {{ student.studentExtId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{ columns.GRADE }}">
          <mat-header-cell *matHeaderCellDef>
            {{ columns.GRADE }}
          </mat-header-cell>
          <mat-cell *matCellDef="let student">
            {{ student.grade }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{ columns.ROLE }}">
          <mat-header-cell *matHeaderCellDef>
            {{ columns.ROLE }}
          </mat-header-cell>
          <mat-cell *matCellDef="let student">
            <mat-form-field class="row-input">
              <mat-select
                placeholder="Select an option"
                [formControlName]="'student-' + student.id.toString()">
                <mat-option
                  *ngFor="let option of incidentTypeOptions"
                  [value]="option.value">
                  {{ option.display }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="{{ columns.ACTION }}">
          <mat-header-cell *matHeaderCellDef> Add </mat-header-cell>
          <mat-cell *matCellDef="let student">
            <ng-container>
              <button
                type="submit"
                [disabled]="!form.get('student-' + student.id.toString()).value"
                mat-flat-button
                [class]="
                  !form.get('student-' + student.id.toString()).value
                    ? 'disabled-button'
                    : 'success-button'
                "
                (click)="
                  addParticipant(
                    student,
                    form.get('student-' + student.id.toString()).value
                  )
                "
                options-id="ADD_STUDENT_TO_ROSTER">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
              </button>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let code; columns: displayedColumns"></mat-row>
      </mat-table>
      <app-pagination
        [tableFilters]="filters.tableFilters"
        (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
    </div>
  </form>

  <mat-card-footer>
    <div class="modal-action-row">
      <button
        type="submit"
        mat-button
        class="modal-submit-button"
        (click)="handleClose()">
        Submit
      </button>
    </div>
  </mat-card-footer>
</div>
