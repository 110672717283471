<form *ngIf="!!schoolForm && !isLoading" [formGroup]="schoolForm" novalidate>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>School Information</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mat-elevation-z3 display-row">
      <div class="column">
        <!-- Name -->
        <mat-form-field class="full-width-input">
          <mat-label> Name<span> *</span></mat-label>
          <input formControlName="name" matInput type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="name"></app-error-display>
        </mat-form-field>
        <!-- Active Term -->
        <mat-form-field
          class="full-width-input"
          *ngIf="isUpdateSchool() && !!schoolTerms?.length">
          <mat-label
            >{{'Active Term'.concat(isUpdateSchool() ? 's': '')}}</mat-label
          >
          <mat-select multiple formControlName="activeTerms">
            <mat-option
              *ngFor="let schoolTerm of schoolTerms"
              [value]="schoolTerm.termId"
              options-id="activeTerms"
              [option]="constantize(schoolTerm.termName)">
              {{schoolTerm.termName}}
            </mat-option>
          </mat-select>
          <app-error-display
            [form]="schoolForm"
            [controlName]="isUpdateSchool() ? 'activeTerms': 'currentTerm'"></app-error-display>
        </mat-form-field>
        <mat-form-field class="full-width-input" *ngIf="!isUpdateSchool()">
          <mat-label
            >{{'Active Term'.concat(isUpdateSchool() ? 's': '')}}</mat-label
          >
          <input
            formControlName="currentTerm"
            id="currentTerm"
            matInput
            type="text" />
          <app-error-display
            [form]="schoolForm"
            [controlName]="isUpdateSchool() ? 'activeTerms': 'currentTerm'"></app-error-display>
        </mat-form-field>
        <!-- referenceId -->
        <mat-form-field class="full-width-input">
          <mat-label>Reference Id<span> *</span></mat-label>
          <input formControlName="referenceId" matInput type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="referenceId"></app-error-display>
        </mat-form-field>
        <!-- Timezone -->
        <div [formGroup]="settingsForm">
          <mat-form-field class="filter-item full-width-input">
            <mat-label>Timezone</mat-label>
            <mat-select
              formControlName="timezone"
              placeholder="Select Timezone">
              <mat-option
                *ngFor="let option of timezoneOptions"
                [value]="option.value">
                {{option.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-error-display
            controlName="timezone"
            [form]="settingsForm"></app-error-display>
          <mat-checkbox
            (change)="onBooleanSettingToggle('email_guardian_invite', $event)"
            color="primary"
            formControlName="email_guardian_invite"
            >Enable Guardian Invite Emails</mat-checkbox
          >
        </div>
      </div>
      <div class="column">
        <mat-form-field class="full-width-input">
          <mat-label> Address</mat-label>
          <input formControlName="address" id="address" matInput type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="address"></app-error-display>
        </mat-form-field>
        <!-- City, city label and input below -->
        <mat-form-field class="full-width-input">
          <mat-label> City</mat-label>
          <input formControlName="city" matInput type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="city"></app-error-display>
        </mat-form-field>
        <div class="row">
          <!-- State, state label and input below -->
          <mat-form-field class="full-width-input">
            <mat-label> State</mat-label>
            <mat-select formControlName="state">
              <mat-option
                *ngFor="let state of stateOptions"
                [value]="state.value"
                options-id="state"
                [option]="state.display">
                {{ state.display }}
              </mat-option>
            </mat-select>
            <app-error-display
              [form]="schoolForm"
              controlName="state"></app-error-display>
          </mat-form-field>
          <!-- Zip, zip label and input below -->
          <mat-form-field class="full-width-input">
            <mat-label> Zip</mat-label>
            <input formControlName="zip" matInput type="text" />
            <app-error-display
              [form]="schoolForm"
              controlName="zip"></app-error-display>
          </mat-form-field>
        </div>
        <!-- Phone -->
        <mat-form-field class="full-width-input">
          <mat-label> Phone</mat-label>
          <input formControlName="telephone" matInput type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="telephone"></app-error-display>
        </mat-form-field>
      </div>
      <div class="column">
        <!-- Fax -->
        <mat-form-field class="full-width-input">
          <mat-label> Fax</mat-label>
          <input
            formControlName="fax"
            id="fax"
            matInput
            nullIfBlank
            type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="fax"></app-error-display>
        </mat-form-field>
        <!-- Attendance Admin Name -->
        <mat-form-field class="full-width-input">
          <mat-label> Attendance Admin Name</mat-label>
          <input
            formControlName="attendanceAdminName"
            id="attendanceAdminName"
            matInput
            type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="attendanceAdminName"></app-error-display>
        </mat-form-field>
        <!-- Principal Name -->
        <mat-form-field class="full-width-input">
          <mat-label> Principal Name</mat-label>
          <input formControlName="principalName" matInput type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="principalName"></app-error-display>
        </mat-form-field>
        <!-- System Admin Name -->
        <mat-form-field class="full-width-input">
          <mat-label> System Admin Name</mat-label>
          <input formControlName="systemAdminName" matInput type="text" />
          <app-error-display
            [form]="schoolForm"
            controlName="systemAdminName"></app-error-display>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="isUserType([UserTypeEnum.INTERNAL_ADMIN])">
    <mat-card-header>
      <mat-card-title>School Settings</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mat-elevation-z3 display-row">
      <div [formGroup]="settingsForm">
        <mat-checkbox
          color="primary"
          (change)="onBooleanSettingToggle('incident', $event)"
          [attr.options-id]="'Incident'"
          formControlName="incident">
          Incident
        </mat-checkbox>
        <mat-checkbox
          color="primary"
          (change)="onBooleanSettingToggle('event', $event)"
          [attr.options-id]="'Event'"
          formControlName="event">
          Event
        </mat-checkbox>
      </div>
    </mat-card-content>
  </mat-card>
</form>
