  <div class="mat-table">
    <mat-table
      class="behavior-codes-interventions-table mat-table"
      [dataSource]="incidentParticipations"
      [ngStyle]="{'min-width': '0px'}"
      matSort
      matSortDisableClear>
      <ng-container matColumnDef="{{columns.INCIDENT_NUMBER}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Incident Number
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.incidentNumber }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.TYPE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Role
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.type }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.REFERRAL_STATUS}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Referral Status
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.referralStatus ? row.referralStatus : 'no referral' }}
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="canViewIncidents" matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          View
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/incident/' + row.incidentId.toString()">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>
  </div>
