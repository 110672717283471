<div class="mat-dialog-header">
    <div class="mat-dialog-title">
      Referral Rejected Note
    </div>
  </div>

  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  <div mat-dialog-content class="content-wrapper">
    <ng-container *ngIf="!isLoading">
        <div [formGroup]="rejectForm" class="form-wrapper"> 
            <textarea
              placeholder="Note goes here"
              formControlName="rejectedNotes"
              class="note-textarea"
              [readonly]="!isReview">
            </textarea>
          </div>
    </ng-container>
  </div>
  
  <div class="modal-action-row">
    <button type="button"  mat-button class="modal-cancel-button" (click)="onCancel()">
        Close
    </button>
    <button *ngIf="isReview" type="button" mat-button class="modal-delete-button" (click)="onSubmit()">
      Reject
    </button>
  </div>