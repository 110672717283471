import { Directive } from '@angular/core';
import { BaseComponent } from '../base-classes/base.component';
import { IStudentsListFilters } from '../../pages/students/students-list-models/students-list-filters.interface';
import { StudentPerformanceDto } from '@whetstoneeducation/hero-common';
import { AppStudentsService } from '../../pages/students/students.service';
import { AppToastManagerService } from '../services/toast-manager.service';
import { TableFilterOptions } from '../tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../tables/table-filters/table-filters-page-key.enum';
import { ITableFilters } from '../tables/table-filters/table-filters.interface';
import { logger } from '../logger';
import { AppPrivilegesService } from '../../pages/auth/privileges.service';

@Directive()
export class BaseStudentListComponent extends BaseComponent {
  public search: string;
  public grade: string;
  public filters: IStudentsListFilters;
  students: StudentPerformanceDto[] = [];
  constructor(
    public studentsService: AppStudentsService,
    public toastService: AppToastManagerService,
    public extras?: { privilegesService: AppPrivilegesService }
  ) {
    super(extras ? { privilegesService: extras.privilegesService } : {});
  }

  async loadFiltersAndStudents(options?: {
    incidentId?: number;
    customRosterId?: number;
    excludeStudentIds?: number[];
  }) {
    this.filters = this.studentsService.getStudentsListDefaultFilters({});
    if (options?.incidentId) {
      this.filters.incidentId = options.incidentId;
    }
    if (options?.customRosterId) {
      this.filters.customRosterId = options.customRosterId;
    }

    if (options?.excludeStudentIds) {
      this.filters.excludedStudentIds = options.excludeStudentIds;
    }

    const studentResult = await this.studentsService.getStudentsList(
      this.filters
    );

    this.students = studentResult.results;

    this.filters.tableFilters.count = studentResult.options.totalItems;
    this.filters.tableFilters.pageLastIds = {
      [studentResult.options.page + 1]: studentResult.options.lastId
    };
  }

  public async updateFilters(newFilters: IStudentsListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.STUDENTS_LIST,
      this.filters.tableFilters
    );
    await this.updateStudentsList(this.filters);
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    const pageLastIds = this.filters.tableFilters.pageLastIds;
    const page = this.filters.tableFilters.page;
    this.filters.tableFilters.lastId = pageLastIds ? pageLastIds[page] || 0 : 0;
    await this.updateStudentsList(this.filters);
    this.isLoading = false;
  }

  public async updateStudentsList(
    filters: IStudentsListFilters
  ): Promise<void> {
    this.isLoading = true;
    try {
      const newData = await this.studentsService.getStudentsList(filters);
      this.students = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
      if (this.filters.tableFilters.pageLastIds) {
        this.filters.tableFilters.pageLastIds[newData.options.page + 1] =
          newData.options.lastId;
      }
      await this.onStudentsUpdated();
    } catch (err) {
      this.toastService.error('Error retrieving students!');
      logger.error(err);
    }
    this.isLoading = false;
  }

  // override this method in child components to handle students updated
  public async onStudentsUpdated(): Promise<void> {}
}
