<div class="blue-card-container" tabindex="0" aria-label="Student Header Information" role="group">
  <div class="student-name">
    <app-student-profile-photo
      [selectedImage]="selectedImage"
      [studentId]="studentId"
      [hideProfileImageSelector]="hideProfileImageSelector">
    </app-student-profile-photo>
    <h2 tabindex="0" [attr.aria-label]="'Student Name: ' + firstName + ' ' + lastName" role="complementary">
      {{firstName}} {{lastName}}
    </h2>
  </div>
  <div class="student-id-data">
    <div tabindex="0" class="student-id-data-container" [attr.aria-label]="'Student ID: ' + studentExtId" role="complementary">
      <span class="id-label">ID:</span>
      <span>{{studentExtId || "N/A"}}</span>
    </div>
    <div tabindex="0" class="student-id-data-container" [attr.aria-label]="'Student Grade: ' + grade" role="complementary">
      <span class="grade-label">Grade:</span>
      <span>{{grade}}</span>
    </div>
    <div tabindex="0" [attr.aria-label]="'Student Group: ' + studentGroupName || 'N/A'" role="complementary">
      <span class="group-label">Student Group:</span>
      <span>{{ studentGroupName || "N/A" }}</span>
    </div>
  </div>
  <div class="right-elements">
    <ng-content></ng-content>
  </div>
</div>
