"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolUpdateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const boolean_transform_1 = require("../../constants/boolean.transform");
const school_settings_dto_1 = require("./school-settings.dto");
class SchoolUpdateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return [
            'id',
            'name',
            'address',
            'city',
            'state',
            'country',
            'zip',
            'telephone',
            'fax',
            'principalName',
            'attendanceAdminName',
            'systemAdminName',
            'schoolType',
            'enablePushNotifications',
            'activeTerms',
            'currentAcademicYear',
            'referenceId',
            'settings'
        ];
    }
    id;
    name;
    address;
    city;
    state;
    country;
    zip;
    telephone;
    fax;
    principalName;
    attendanceAdminName;
    systemAdminName;
    schoolType;
    enablePushNotifications;
    activeTerms;
    currentAcademicYear;
    referenceId;
    settings;
    mapFields(source, skipNested = false, skipTransforms = false) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
}
exports.SchoolUpdateDto = SchoolUpdateDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)({ message: 'Invalid ID' }),
    __metadata("design:type", Number)
], SchoolUpdateDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNotEmpty)({ message: 'School name is required' }),
    (0, class_validator_1.MaxLength)(100, { message: 'School name too long' }),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(100, { message: 'Address too long' }),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "address", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(100, { message: 'City too long' }),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "city", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(24, { message: 'State name too long' }),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "state", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(36, { message: 'Country name too long' }),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "country", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPostalCode)('US', { message: 'Must be valid US zip code' }),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "zip", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPhoneNumber)('US', { message: 'Must be valid US phone number' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "telephone", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.ValidateIf)((schoolDetails) => schoolDetails.fax !== ''),
    (0, class_validator_1.IsPhoneNumber)('US', { message: 'Must be valid US phone number' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "fax", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(50, { message: 'Principal name too long' }),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "principalName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.MaxLength)(50, { message: 'Attendance admin name too long' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "attendanceAdminName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.MaxLength)(50, { message: 'System admin name too long' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "systemAdminName", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], SchoolUpdateDto.prototype, "schoolType", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], SchoolUpdateDto.prototype, "enablePushNotifications", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ArrayUnique)(),
    __metadata("design:type", Array)
], SchoolUpdateDto.prototype, "activeTerms", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.MaxLength)(5, {
        message: 'Current academic year too long, maximum 5 characters'
    }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "currentAcademicYear", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolUpdateDto.prototype, "referenceId", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    (0, class_transformer_1.Type)(() => school_settings_dto_1.SchoolSettings),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", school_settings_dto_1.SchoolSettings)
], SchoolUpdateDto.prototype, "settings", void 0);
