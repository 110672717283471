import { Component, Input } from '@angular/core';
import { HeroDispositionResponseDto } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-hero-disposition-view',
  templateUrl: './hero-disposition-view.component.html',
  styleUrls: ['./hero-disposition-view.component.scss']
})
export class HeroDispositionViewComponent {
  @Input() heroDisposition: HeroDispositionResponseDto;
}
