import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { AppLoadingBarService } from './shared/services/loading-bar.service';
import { AppNavbarService } from './shared/navbar/navbar.service';
import { BaseComponent } from './shared/base-classes/base.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { AppSessionHandlerService } from './pages/auth/session-handler.service';
import { UserTypeEnum } from '@whetstoneeducation/hero-common';

declare global {
  interface Window {
    googleTranslateElementInit: () => void;
    google: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.template.html',
  styleUrls: ['./app.scss']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('appContentScrollContainer')
  public appContentScrollContainer: ElementRef;
  public isLoggedIn: boolean;
  public isLogin = false;
  private destroy$ = new Subject<void>();

  public constructor(
    private domSanitizer: DomSanitizer,
    public changeDetectorRef: ChangeDetectorRef,
    public sessionHandlerService: AppSessionHandlerService,
    public loadingBarService: AppLoadingBarService,
    public navbarService: AppNavbarService,
    public router: Router
  ) {
    super();

    // if router is on /login set the flag
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLogin = event.url === '/login';
      }
    });
    this.isLogin = this.router.url === '/login';
  }

  public ngOnInit(): void {
    this.handleBasicSubscriptions();
    this.handleRouterEvents();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleRouterEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isLoading = true;
      } else if (event instanceof NavigationEnd) {
        this.isLoading = false;
        this.scrollToTop();
      } else if (event instanceof NavigationCancel) {
        this.isLoading = false;
        this.reloadPage();
      } else if (event instanceof NavigationError) {
        this.isLoading = false;
      }
    });
  }

  private scrollToTop(): void {
    this.appContentScrollContainer.nativeElement.scrollTop = 0;
  }

  private reloadPage(): void {
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  public handleBasicSubscriptions(): void {
    this.loadingBarService.loadingStatusChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
      });
    this.sessionHandlerService
      .getIsLoggedInObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
  }

  private showSupportButtons(): boolean {
    let show = true;
    if (
      this.isUserType([UserTypeEnum.STUDENT, UserTypeEnum.GUARDIAN]) ||
      !this.isLoggedIn
    ) {
      show = false;
    }
    return show;
  }
}
