"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeroDispositionResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class HeroDispositionResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'id',
            'behaviorCode',
            'behaviorCodeReaction',
            'behaviorCodeEntry',
            'finalized'
        ];
    }
    id;
    behaviorCode;
    behaviorCodeEntry;
    behaviorCodeReaction;
    finalized;
}
exports.HeroDispositionResponseDto = HeroDispositionResponseDto;
