import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { FormGroup } from '@angular/forms';
import { IDisplayData } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-disposition-edit',
  templateUrl: './disposition-edit.component.html',
  styleUrls: ['./disposition-edit.component.scss']
})
export class DispositionEditComponent extends BaseComponent {
  @Input() form: FormGroup;
  @Input() dispositionOptions: IDisplayData[];
  @Input() durationOptions: IDisplayData[];
  @Output() dispositionDeleted: EventEmitter<void> = new EventEmitter<void>();
  constructor() {
    super();
  }

  public deleteDisposition() {
    this.dispositionDeleted.emit();
  }
}
