"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralDetailResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const incidents_1 = require("../incidents");
const students_1 = require("../students");
const hero_dispositions_1 = require("../hero-dispositions");
class ReferralDetailResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'id',
            'incident',
            'student',
            'studentWithSpecialNeedsId',
            'studentWithSpecialNeeds',
            'bullyingId',
            'bullying',
            'motivationId',
            'motivation',
            'basisOfBullyingId',
            'basisOfBullying',
            'previousActionTakenId',
            'previousActionTaken',
            'incidentContextId',
            'incidentContext',
            'severityLevelId',
            'severityLevel',
            'dueProcessId',
            'dueProcess',
            'othersInvolvedId',
            'othersInvolved',
            'status',
            'notes',
            'rejectedNotes'
        ];
    }
    id;
    incident;
    student;
    studentWithSpecialNeeds;
    studentWithSpecialNeedsId;
    bullying;
    bullyingId;
    motivation;
    motivationId;
    basisOfBullying;
    basisOfBullyingId;
    previousActionTaken;
    previousActionTakenId;
    incidentContext;
    incidentContextId;
    severityLevel;
    severityLevelId;
    dueProcess;
    dueProcessId;
    othersInvolved;
    othersInvolvedId;
    status;
    notes;
    localDispositions;
    stateDispositions;
    heroDisposition;
    rejectedNotes;
}
exports.ReferralDetailResponseDto = ReferralDetailResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", incidents_1.IncidentResponseDto)
], ReferralDetailResponseDto.prototype, "incident", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", students_1.StudentDetailResponseDto)
], ReferralDetailResponseDto.prototype, "student", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "studentWithSpecialNeeds", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "studentWithSpecialNeedsId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "bullying", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "bullyingId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "motivation", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "motivationId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "basisOfBullying", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "basisOfBullyingId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "previousActionTaken", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "previousActionTakenId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "incidentContext", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "incidentContextId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "severityLevel", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "severityLevelId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "dueProcess", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "dueProcessId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ReferralDetailResponseDto.prototype, "othersInvolved", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], ReferralDetailResponseDto.prototype, "othersInvolvedId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], ReferralDetailResponseDto.prototype, "status", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], ReferralDetailResponseDto.prototype, "notes", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], ReferralDetailResponseDto.prototype, "localDispositions", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], ReferralDetailResponseDto.prototype, "stateDispositions", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", hero_dispositions_1.HeroDispositionResponseDto)
], ReferralDetailResponseDto.prototype, "heroDisposition", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], ReferralDetailResponseDto.prototype, "rejectedNotes", void 0);
