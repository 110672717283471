import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AppMySettingsService } from './my-settings.service';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { RESPONSIVE_GRID_PRESETS } from 'src/app/shared/responsive-grid-columns';
import { Subscription } from 'rxjs';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../shared/validation/validation.util';
import {
  IDisplayData,
  LoginResponseDto,
  SelfUpdateDto,
  StateEnum,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { IMySettingsResolverData } from './my-settings-resolver-data.interface';
import { AppSchoolsServices } from '../schools/schools.service';
import { AppAuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AppPasswordDialogComponent } from '../../shared/password-dialog/password-dialog.component';
import { AppUsersServices } from '../users/users.service';
import { AppNavbarService } from '../../shared/navbar/navbar.service';
import { AppPageHeaderService } from '../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

@Component({
  selector: 'app-my-settings',
  templateUrl: './my-settings.template.html',
  styleUrls: ['./my-settings.scss']
})
export class AppMySettingsComponent
  extends BaseComponent
  implements OnDestroy, AfterViewInit
{
  protected readonly UserTypeEnum = UserTypeEnum; // assign to constant so it can be used in template
  public stateOptions: IDisplayData[] = Object.values(StateEnum).map(
    (value) => ({
      value,
      display: value
    })
  );
  public currentUser: LoginResponseDto;
  public user: SelfUpdateDto;
  public userForm: FormGroup;
  public valueChangesSubscription: Subscription;
  public pageHeaderSubscription: Subscription;
  public schoolGroupOptions: IDisplayData[];
  public schoolOptions: IDisplayData[];

  constructor(
    private formBuilder: FormBuilder,
    private toastService: AppToastManagerService,
    private usersService: AppUsersServices,
    public mySettingsService: AppMySettingsService,
    public route: ActivatedRoute,
    public pageHeaderService: AppPageHeaderService,
    public schoolService: AppSchoolsServices,
    public authService: AppAuthService,
    public passwordDialog: MatDialog,
    public navbarService: AppNavbarService
  ) {
    super({ route });
    this.setupSaveBar();
    this.loadData(this.route.snapshot.data.data);
  }

  ngAfterViewInit() {
    this.valueChangesSubscription = this.userForm.valueChanges.subscribe(
      async () => {
        this.user = await validateAndGetValue<SelfUpdateDto>(
          this.userForm,
          SelfUpdateDto
        );
      }
    );
  }

  ngOnDestroy() {
    this.pageHeaderSubscription.unsubscribe();
    this.valueChangesSubscription.unsubscribe();
  }

  public get responsiveGridPresets() {
    return RESPONSIVE_GRID_PRESETS.TWO_COLUMN;
  }

  public setupSaveBar() {
    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (
            action === HeaderButtonAction.SAVE &&
            formCanSave(this.userForm, this.toastService)
          ) {
            await this.saveSettings();
          }
        }
      );
  }

  public loadData(data: IMySettingsResolverData) {
    try {
      const user = data.user;
      this.currentUser = this.StorageManager.getLoggedInUser();
      this.user = plainToInstance(
        SelfUpdateDto,
        new SelfUpdateDto({
          id: user.id
        }),
        {
          enableImplicitConversion: true,
          exposeUnsetFields: true
        }
      );
      this.user.mapFields(user);
      this.user.currentSchoolId = this.currentUser.currentSchoolId;
      this.user.schoolGroupId = this.currentUser.schoolGroupId;
      if (data.schoolGroups) {
        this.schoolGroupOptions = data.schoolGroups.map((group) => {
          return { value: group.id, display: group.name };
        });
      } else {
        this.schoolGroupOptions = [
          { value: user.schoolGroup.id, display: user.schoolGroup.name }
        ];
      }
      this.schoolOptions = data.schools.map((school) => {
        return { value: school.id, display: school.name };
      });
      this.userForm = dtoToFormGroup(this.user, this.formBuilder, {
        mapId: true,
        ...(this.schoolGroupOptions.length <= 1 && {
          disable: ['schoolGroupId']
        }),
        ...(this.schoolOptions.length <= 1 && { disable: ['schoolId'] })
      });
    } catch (error) {
      this.toastService.error(
        'An error occurred when attempting to load your settings!'
      );
    }
  }

  public async saveSettings() {
    try {
      if (
        this.user.schoolGroupId &&
        this.user.schoolGroupId !== this.currentUser.schoolGroupId
      ) {
        this.currentUser = await this.authService.updateActiveSchoolGroup(
          this.user.schoolGroupId
        );
        this.user.mapFields(this.currentUser);
      }
      if (
        this.user.currentSchoolId &&
        this.user.currentSchoolId !== this.currentUser.currentSchoolId
      ) {
        this.currentUser = await this.authService.updateActiveSchool(
          this.user.currentSchoolId
        );
        this.user.mapFields(this.currentUser);
      }
      await this.mySettingsService.saveSettings(this.user);
      this.toastService.success('Settings saved successfully!');
      this.navbarService.updateData();
    } catch (error) {
      this.toastService.error(
        'An error occurred when attempting to save your settings!'
      );
    }
  }

  public async clearSchoolSettings(clearSchoolGroup = true) {
    if (clearSchoolGroup && this.currentUser.type === UserTypeEnum.INTERNAL_ADMIN) {
      this.userForm.controls.schoolGroupId.setValue(null);
    }
    this.userForm.controls.currentSchoolId.setValue(null);
    const user = await this.usersService.clearSchoolSettings(this.user.id);
    this.StorageManager.saveUserInfo(user);
    this.currentUser = user;
    this.user.mapFields(user);
    this.mySettingsService.updateNavBar();
  }

  public async updateSchoolOptions(event) {

    await this.clearSchoolSettings(false);

    const schoolGroupId = event.value;

    const schools = await this.schoolService.getSchoolsList({
      schoolGroupId: schoolGroupId || null,
      searchInput: null,
      tableFilters: {
        count: 0,
        page: 1,
        itemsPerPage: 1000,
        active: 'Name',
        direction: 'asc'
      }
    });

    this.schoolOptions = schools.results.map((school) => {
      return { value: school.id, display: school.name };
    });

    this.schoolOptions.unshift({ value: null, display: '' });
  }

  public async setPassword() {
    this.passwordDialog
      .open(AppPasswordDialogComponent, {
        width: '500px',
        data: {
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.mySettingsService
            .setPassword(this.user.id, result)
            .then(() => {
              this.toastService.success('Password updated successfully!');
            })
            .catch(() => {
              this.toastService.error(
                'An error occurred when attempting to update your password!'
              );
            });
        }
      });
  }
}
