<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="singleColumnLayout">
  <form [formGroup]="referralForm">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title class="card-title">Referral For</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="three-column-layout">
        <div class="one-third">
          <mat-form-field>
            <mat-label>Student</mat-label>
            <input disabled="true" matInput placeholder="Student" [value]="student.firstName + ' ' + student.lastName" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Grade</mat-label>
            <input disabled="true" matInput placeholder="Grade" [value]="student.grade" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Incident Date</mat-label>
            <input  disabled="true" matInput placeholder="Date" [value]="DateUtil.convertTimestampToDisplayDate(incident.date)" />
          </mat-form-field>
          <mat-form-field class="notes-text-area">
            <mat-label>Notes</mat-label>
            <textarea
              class="text-area"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="15"
              formControlName="notes"
              matInput
              placeholder="Notes"></textarea>
          </mat-form-field>
        </div>
        <div class="one-third">
          <mat-form-field>
            <mat-label>Student Id</mat-label>
            <input  disabled="true" matInput placeholder="Student" [value]="student.studentExtId" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Referral By</mat-label>
            <input  disabled="true" matInput placeholder="Referral By" [value]="incident.reporter.firstName + ' ' + incident.reporter.lastName" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Guardian Name</mat-label>
            <input  disabled="true" matInput placeholder="Guardian" [value]="student.guardians[0] ? (student?.guardians[0]?.firstName + ' ' + student?.guardians[0]?.lastName) : 'no guardian'" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Student With Special Needs</mat-label>
            <mat-select formControlName="studentWithSpecialNeedsId">
              <mat-option *ngFor="let option of studentWithSpecialNeedsOptions" [value]="option.value">
                {{ option.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Motivation</mat-label>
            <mat-select formControlName="motivationId">
              <mat-option *ngFor="let option of motivationOptions" [value]="option.value">
                {{ option.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Others Involved</mat-label>
            <mat-select formControlName="othersInvolvedId">
              <mat-option *ngFor="let option of othersInvolvedOptions" [value]="option.value">
                {{ option.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="one-third">
          <mat-form-field>
            <mat-label>Guardian Email</mat-label>
            <input disabled="true" matInput placeholder="Student" [value]="(student?.guardians[0]?.email) || 'no guardian'" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Bullying</mat-label>
            <mat-select formControlName="bullyingId">
              <mat-option *ngFor="let option of bullyingOptions" [value]="option.value">
                {{ option.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Basis Of Bullying</mat-label>
            <mat-select formControlName="basisOfBullyingId">
              <mat-option *ngFor="let option of basisOfBullyingOptions" [value]="option.value">
                {{ option.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Previous Action Taken</mat-label>
            <mat-select formControlName="previousActionTakenId">
              <mat-option *ngFor="let option of previousActionTakenOptions" [value]="option.value">
                {{ option.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  </form>
  <form *ngIf="isReviewing" [formGroup]="referralReviewForm" >
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="card-title">Administration Section</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="admin-section-form-container">

        <mat-form-field>
          <mat-label>Incident Context</mat-label>
          <mat-select formControlName="incidentContextId">
            <mat-option *ngFor="let option of incidentContextOptions" [value]="option.value">
              {{ option.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Severity Level</mat-label>
          <mat-select formControlName="severityLevelId">
            <mat-option *ngFor="let option of severityLevelOptions" [value]="option.value">
              {{ option.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Due Process</mat-label>
          <mat-select formControlName="dueProcessId">
            <mat-option *ngFor="let option of dueProcessOptions" [value]="option.value">
              {{ option.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <mat-card *ngIf="isReviewing">
    <mat-card-header>
      <mat-card-title class="card-title">Hero Disposition</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-hero-disposition-view *ngIf="referral.heroDisposition.finalized" [heroDisposition]="referral.heroDisposition"></app-hero-disposition-view>
      <app-hero-disposition-edit *ngIf="!referral.heroDisposition.finalized"  [heroDisposition]="referral.heroDisposition" [form]="heroDispositionForm" [behaviorCodeOptions]="behaviorCodeOptions" [behaviorCodeReactionOptions]="behaviorCodeReactionOptions"></app-hero-disposition-edit>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="isReviewing && localDispositionForms" appearance="outlined">
    <mat-card-header>
      <mat-card-title class="card-title">Local Disposition</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="disposition-form-container" *ngFor="let id of Object.keys(localDispositionForms)">
        <app-disposition-edit (dispositionDeleted)="deleteDisposition(id)" [form]="localDispositionForms[id]" [dispositionOptions]="localDispositionOptions" [durationOptions]="durationOptions"></app-disposition-edit>
      </div>
      <div (keydown)="addDisposition('local')" class="add-disposition-button" (click)="addDisposition('local')">
        + Add Disposition
    </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="isReviewing && stateDispositionForms" appearance="outlined" class="disposition-card">
    <mat-card-header>
      <mat-card-title class="card-title">State Disposition</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="disposition-form-container" *ngFor="let id of Object.keys(stateDispositionForms)">
        <app-disposition-edit (dispositionDeleted)="deleteDisposition(id)" [form]="stateDispositionForms[id]" [dispositionOptions]="stateDispositionOptions" [durationOptions]="durationOptions"></app-disposition-edit>
      </div>
      <div (keydown)="addDisposition('local')" class="add-disposition-button" (click)="addDisposition('state')">
       + Add Disposition
      </div>
    </mat-card-content>
  </mat-card>
</div>
