import { Injectable } from '@angular/core';
import { RoleResponseDto } from '@whetstoneeducation/hero-common';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppRolesServices } from '../roles.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { plainToInstance } from 'class-transformer';
import { IRoleCreateEditResolverDataInterface } from './role-create-edit-resolver-data.interface';
import { AppBehaviorCodeTypesService } from '../../behavior-code-types/behavior-code-types.service';

@Injectable({ providedIn: 'root' })
export class AppRoleResolver extends BaseResolver<IRoleCreateEditResolverDataInterface> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public rolesService: AppRolesServices,
    public behaviorCodeTypesService: AppBehaviorCodeTypesService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IRoleCreateEditResolverDataInterface> {
    const id = route.paramMap.get('id');
    const user = this.StorageManager.getLoggedInUser();

    const schoolGroupId = user.schoolGroupId;

    const behaviorCodeTypesCall =
      this.behaviorCodeTypesService.getBehaviorCodeTypesListDisplayData(
        schoolGroupId,
        true
      );
    if (id == 'new') {
      const role = plainToInstance(RoleResponseDto, {
        name: '',
        privileges: []
      });

      return { role, behaviorCodeTypes: await behaviorCodeTypesCall };
    } else {
      const [role, behaviorCodeTypes] = await Promise.all([
        this.rolesService.getRoleById(parseInt(id)),
        behaviorCodeTypesCall
      ]);
      return { role, behaviorCodeTypes };
    }
  }
}
