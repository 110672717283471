<div *ngIf="!isLoading" class="incident-config-container twoColumnLayout">
  <div class="column">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="card-title">Incident</mat-card-title>
      </mat-card-header>
      <mat-card-content class="category-option-content">
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.INCIDENT_TYPES"
          [display]="'Incident Types'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.INCIDENT_TYPES
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.INCIDENT_PLACES"
          [display]="'Incident Places'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.INCIDENT_PLACES
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="
            DefaultOptionCategoriesIncidentEnum.INCIDENT_ROLES_AND_REFERRALS
          "
          [display]="'Incident Roles & Referrals'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.INCIDENT_ROLES_AND_REFERRALS
          "
          (onCategoryChange)="onCategoryChange($event)" />
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title class="card-title">Referrals</mat-card-title>
      </mat-card-header>
      <mat-card-content class="category-option-content">
        <app-incident-config-option-category-menu-item
          [value]="
            DefaultOptionCategoriesIncidentEnum.STUDENT_WITH_SPECIAL_NEEDS
          "
          [display]="'Student with Special Needs'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.STUDENT_WITH_SPECIAL_NEEDS
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.MOTIVATION"
          [display]="'Motivation'"
          [selected]="
            selectedCategory === DefaultOptionCategoriesIncidentEnum.MOTIVATION
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.OTHERS_INVOLVED"
          [display]="'Others Involved'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.OTHERS_INVOLVED
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.BULLYING"
          [display]="'Bullying'"
          [selected]="
            selectedCategory === DefaultOptionCategoriesIncidentEnum.BULLYING
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.BASIS_OF_BULLYING"
          [display]="'Basis of Bullying'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.BASIS_OF_BULLYING
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.PREVIOUS_ACTION_TAKEN"
          [display]="'Previous Action Taken'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.PREVIOUS_ACTION_TAKEN
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.LOCAL_DISPOSITION"
          [display]="'Local Disposition'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.LOCAL_DISPOSITION
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.STATE_DISPOSITION"
          [display]="'State Disposition'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.STATE_DISPOSITION
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.INCIDENT_CONTEXT"
          [display]="'Incident Context'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.INCIDENT_CONTEXT
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.SEVERITY_LEVEL"
          [display]="'Severity Level'"
          [selected]="
            selectedCategory ===
            DefaultOptionCategoriesIncidentEnum.SEVERITY_LEVEL
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.DUE_PROCESS"
          [display]="'Due Process'"
          [selected]="
            selectedCategory === DefaultOptionCategoriesIncidentEnum.DUE_PROCESS
          "
          (onCategoryChange)="onCategoryChange($event)" />
        <app-incident-config-option-category-menu-item
          [value]="DefaultOptionCategoriesIncidentEnum.DURATION"
          [display]="'Duration'"
          [selected]="
            selectedCategory === DefaultOptionCategoriesIncidentEnum.DURATION
          "
          (onCategoryChange)="onCategoryChange($event)" />
      </mat-card-content>
    </mat-card>
  </div>
  <div class="column">
    <mat-card *ngIf="selectedCategory">
      <mat-card-header>
        <mat-card-title class="card-title">
          {{ DefaultOptionCategoriesIncidentDisplayValue[selectedCategory] }}
          Name
        </mat-card-title>
        <button
          (click)="addOption()"
          mat-flat-button
          class="action-button icon-button">
          <fa-icon [icon]="['fas', 'plus']" />
        </button>
      </mat-card-header>
      <mat-card-content class="category-option-content">
        <div
          class="option-container"
          *ngFor="let option of optionsDict[selectedCategory].options">
          <div class="option-name">{{ option.name }}</div>
          <div class="option-actions">
            <button
              (click)="editOption(option)"
              mat-flat-button
              class="action-button icon-button"
              [attr.data-test-id]="'edit-option-' + option.id">
              <fa-icon [icon]="['fas', 'edit']" />
            </button>
            <button
              (click)="deleteOption(option)"
              mat-flat-button
              class="action-button icon-button"
              [attr.data-test-id]="'delete-option-' + option.id">
              <fa-icon [icon]="['fas', 'trash-alt']" />
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
