import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AppStudentsService } from '../../students.service';
import {
  StudentProfileIncidentParticipantListResponse,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';

enum IncidentHistoryColumnsEnum {
  INCIDENT_NUMBER = 'incidentNumber',
  TYPE = 'type',
  REFERRAL_STATUS = 'referralStatus',
  ACTIONS = 'actions'
}

@Component({
  selector: 'app-incident-history',
  templateUrl: './incident-history.component.html',
  styleUrls: ['./incident-history.component.scss']
})
export class IncidentHistoryComponent extends BaseComponent implements OnInit {
  @Input() studentId: number;
  public incidentParticipations: StudentProfileIncidentParticipantListResponse[];
  public canViewIncidents: boolean = false;

  loading: boolean = false;

  constructor(public studentsService: AppStudentsService) {
    super();
  }

  async ngOnInit() {
    this.loading = true;
    this.incidentParticipations =
      await this.studentsService.getStudentIncidentHistory(this.studentId);

    const user = this.StorageManager.getLoggedInUser();

    this.canViewIncidents = [
      UserTypeEnum.SCHOOL,
      UserTypeEnum.INTERNAL_ADMIN,
      UserTypeEnum.GROUP_ADMIN
    ].includes(user.type);

    this.loading = false;
  }

  public get displayedColumns() {
    const columns = [
      IncidentHistoryColumnsEnum.INCIDENT_NUMBER,
      IncidentHistoryColumnsEnum.TYPE,
      IncidentHistoryColumnsEnum.REFERRAL_STATUS
    ];
    if (this.canViewIncidents) {
      columns.push(IncidentHistoryColumnsEnum.ACTIONS);
    }
    return columns;
  }

  protected readonly columns = IncidentHistoryColumnsEnum;
}
