export const getRoutes = {
  GOOGLE_LOGIN: '/auth/google',
  AUTH_VERIFY: '/auth/session',
  VERIFY_LINK: '/auth/verify-link',
  COMPLETE_LINK: '/auth/complete-link',
  BEHAVIOR_CODE_BY_ID: '/behavior-codes/:id',
  BEHAVIOR_CODES_LIST: '/behavior-codes',
  BEHAVIOR_CODES_ALL_LIST: '/behavior-codes/all',
  BEHAVIOR_CODES_LIST_BY_BEHAVIOR_CODE_TYPE:
    '/behavior-codes/behavior-code-type/:behaviorCodeTypeId',
  BEHAVIOR_CODES_ACCRUED_REPORT: '/behavior-codes/accrued-report',
  BEHAVIOR_CODE_TYPES_LIST: '/behavior-code-type',
  BEHAVIOR_CODE_TYPE_BY_NAME: '/behavior-code-type/name/:name',
  BEHAVIOR_CODE_TYPES_LIST_WITH_CODES: '/behavior-code-type/behavior-codes',
  BEHAVIOR_CODE_TYPES_LIST_DISPLAY_DATA:
    '/behavior-code-type/display-data/:schoolGroupId',
  BEHAVIOR_CODE_REACTIONS_LIST: '/behavior-code-reactions',
  BEHAVIOR_CODE_NEXT_POSSIBLE_REACTION_OPTIONS:
    '/behavior-code-reactions/reaction-options',
  BEHAVIOR_CODE_NEXT_POSSIBLE_REACTION:
    '/behavior-code-reactions/possible-next-reaction',
  BEHAVIOR_CODE_REACTION_BY_ID: '/behavior-code-reactions/:id',
  BEHAVIOR_CODE_INTERVENTIONS_LIST: '/behavior-code-interventions',
  BEHAVIOR_CODE_INTERVENTION_BY_ID: '/behavior-code-interventions/:id',
  BEHAVIOR_CODE_NOTIFICATIONS_LIST: '/behavior-code-notifications',
  BEHAVIOR_CODE_NOTIFICATIONS_BY_ID: '/behavior-code-notifications/:id',
  BELL_SCHEDULE_BY_ID: '/bell-schedules/:id',
  BELL_SCHEDULES_LIST: '/bell-schedules',
  BELL_SCHEDULE_PERIOD_BY_ID: '/bell-schedule-periods/:id',
  BELL_SCHEDULE_PERIODS_LIST: '/bell-schedule-periods/by-school/:schoolId',
  CALENDAR_DAY_BY_ID: '/calendar-days/:id',
  CALENDAR_DAY_BY_TIMESTAMP: '/calendar-days/timestamp/:timestamp',
  CALENDAR_DAY_LIST: '/calendar-days',
  CALENDAR: '/calendar-days/calendar/:schoolId',
  CALENDAR_DAY_DROPDOWN_DATA: '/calendar-days/dropdown-data/:schoolId',
  INTERVENTIONS_LIST: '/interventions',
  INTERVENTION_BY_ID: '/interventions/:id',
  REACTION_BY_ID: '/reactions/:id',
  REACTIONS_LIST: '/reactions',
  REACTIONS_LIST_DISPLAY_DATA: '/reactions/display-options',
  ROLE_BY_ID: '/roles/:id',
  ROLE_LIST: '/roles',
  SCHOOL_BY_ID: '/schools/:id',
  SCHOOL_GROUP: '/school-groups/:id',
  SCHOOL_GROUP_LIST: '/school-groups',
  SCHOOL_LIST: '/schools',
  SCHOOL_OPTIONS: '/schools/options',
  SETTINGS_BY_SCHOOL_GROUP: '/settings/school_group/:id',
  STUDENT_BY_ID: '/students/:id',
  STUDENT_PROFILE: '/students/:id/profile',
  STUDENT_GRADES: '/students/grades',
  STUDENTS_LIST: '/students',
  STUDENT_GROUP_BY_ID: '/student-groups/:id',
  STUDENT_GROUPS_LIST: '/student-groups',
  STUDENT_GROUPS_LIST_UNPAGINATED: '/student-groups/all',
  USER_BY_ID: '/users/:id',
  USER_LIST: '/users',
  USER_SELF: '/users/:id/self',
  USERS_PRIVILEGES: '/users/:id/privileges',
  USER_AVAILABLE_ROLES: '/users/:id/available-roles',
  ACTIVATE_GUARDIANS: '/guardians/activate-all',
  ACTIVATE_SINGLE_GUARDIAN: '/guardians/activate-single/:id',
  SUBSCRIPTION_BY_SCHOOL_ID: '/subscriptions/:schoolId',
  SCHEDULED_REACTIONS_LIST: '/scheduled-reactions',
  SCHEDULED_REACTION_BY_ID: '/scheduled-reactions/:id',
  EXPORT_SETTINGS_LIST: '/export/settings',
  EXPORT_SETTINGS_BY_ID: '/export/settings/:id',
  EXPORT_BEHAVIOR_ENTRY_DAILY_ACTIVITY_REPORT:
    '/behavior-code-entries/download-daily-activity-report',
  RUN_EXPORT_BY_ID: '/export/run/:id',
  TOP_EXPORTS: '/export/top-exports',
  SFTP_SETTINGS_LIST: '/sftp/settings',
  SFTP_SETTINGS_BY_ID: '/sftp/settings/:id',
  RUN_IMPORT: '/sftp/imports/run/:id',
  SFTP_ACCOUNTS_LIST: '/sftp/accounts',
  SFTP_ACCOUNT_BY_ID: '/sftp/accounts/:id',
  SFTP_ACCOUNT_FIND_EXISTING: '/sftp/accounts/existing',
  SFTP_ACCOUNT_FIND_MATCHING: '/sftp/accounts/matching',
  SFTP_IMPORTS_LIST: '/sftp/imports',
  SFTP_IMPORT_BY_ID: '/sftp/imports/:id',
  SFTP_IMPORT_STATS: '/sftp/import-stats/:importSubject',
  SFTP_TOP_IMPORTS: '/sftp/top-imports',
  TOP_PERFORMERS: '/students/top-performers/:schoolId',
  TOP_BEHAVIOR_CODES: '/behavior-codes/top-behavior-codes/:interval',
  TODAY_SCHEDULE: '/calendar-days/schedule/:schoolId',
  IMPORT_CLOUD_FILE: '/import-file/from-cloud',
  BEHAVIOR_ENTRY_DAILY_ACTIVITY_REPORT:
    '/behavior-code-entries/daily-activity-report',
  BEHAVIOR_CODE_DROPDOWN: '/behavior-codes/dropdown/:schoolId',
  BEHAVIOR_CODES_WITH_NOTIFICATIONS:
    '/behavior-code-notification-entries/behavior-codes-dropdown/:schoolId',
  BEHAVIOR_CODE_NOTIFICATION_ENTRY_CONTENT:
    '/behavior-code-notification-entries/subject-body',
  REACTION_ENTRY_LIST: '/reaction-entry',
  REACTION_ENTRY_BY_ID: '/reaction-entry/:id',
  REACTION_ENTRY_LIST_BY_SCHEDULED_REACTION:
    '/reaction-entry/scheduled-reaction/:scheduledReactionId',
  REACTION_ENTRIES_BY_BEHAVIOR_CODE_ENTRY_IDS:
    '/reaction-entry/behavior-code-entry',
  USER_DROPDOWN: '/users/dropdown/:schoolId',
  NOTIFICATION_SETTINGS_LIST: '/notification/settings',
  NOTIFICATION_SETTINGS_BY_ID: '/notification/settings/:id',
  NOTIFICATION_TEMPLATE_LIST: '/notification/templates',
  NOTIFICATION_TEMPLATE_ALL: '/notification/templates/all',
  NOTIFICATION_TEMPLATE_BY_ID: '/notification/templates/:id',
  COMMUNICATIONS_REPORT:
    '/behavior-code-notification-entries/communications-report',
  REFRESH_BEHAVIOR_CODE_NOTIFICATION_ENTRIES:
    '/behavior-code-notification-entries/refresh/:schoolId',
  BEHAVIOR_CODE_REACTION_SCHEDULED_REACTION_OPTIONS:
    '/behavior-code-reactions/scheduled-reaction-options',
  RESET_PASSWORD: '/auth/reset-password',
  REPORTER_OPTIONS: '/users/reporter-options/:schoolId',
  BEHAVIOR_HISTORY: '/reaction-entry/behavior-history/:id',
  BEHAVIOR_LATEST_HISTORY: '/reaction-entry/behavior-latest-history/:id',
  BEHAVIOR_CODE_ENTRY_BY_ID: '/behavior-code-entries/:id',
  BEHAVIOR_CODE_ENTRY_SCHOOL_ACTIVITY_REPORT:
    '/behavior-code-entries/school-activity-report',
  SCHOOLWIDE_POINTS_REPORT: '/reaction-entry/schoolwide-points-report',
  CALENDAR_EXISTS: '/calendar-days/exists/:schoolId',
  TEACHER_ROSTERS: '/teacher-rosters/by-periods',
  TEACHER_ROSTERS_PERIODS: '/teacher-rosters/:instructorCode/periods',
  STUDENT_COURSE_SCHEDULES: '/students/:id/course-schedules',
  GUARDIAN_STUDENTS: '/guardians/:id/students',
  SCHOOL_TERMS: '/schools/:id/terms',
  DOWNLOAD_IMPORT_REPORT: '/sftp/import-report/:id',
  DOWNLOAD_IMPORT_FILE: '/sftp/import-file/:id',
  BEHAVIOR_CODE_BY_SCHOOLS_OPTIONS: '/behavior-codes/by-schools-options',
  BEHAVIOR_CODES_FAST_TRACK: '/behavior-codes/fast-track',
  EXPORT_COMPLIANCE_REPORT: '/reaction-entry/export-compliance-report',
  CUSTOM_ROSTERS_LIST: '/custom-rosters',
  CUSTOM_ROSTER_BY_ID: '/custom-rosters/:id',
  EXPORT_ACCRUED_REPORT: '/behavior-codes/export-accrued-report',
  EXPORT_SCHOOLWIDE_POINTS_REPORT:
    '/reaction-entry/export-schoolwide-points-report',
  EXPORT_BEHAVIOR_CODE_ENTRY_SCHOOL_ACTIVITY_REPORT:
    '/behavior-code-entries/export-school-activity-report',
  INCIDENT_OPTIONS: '/options/incident',
  REFERRAL_OPTIONS: '/options/referral',
  OPTIONS_BY_CATEGORY: '/options/category/:category',
  INCIDENT_BY_ID: '/incidents/:id',
  INCIDENT_LIST: '/incidents',
  REFERRAL_BY_ID: '/referrals/:id',
  STUDENT_INCIDENT_HISTORY:
    '/incidents/student-profile-incident-participants/:studentId',
  BEHAVIOR_CODES_LIST_BY_INCIDENT_BEHAVIOR_CODE_TYPE:
    '/behavior-codes/behavior-code-type-by-incident'
};
export type GetRoutes = typeof getRoutes;
