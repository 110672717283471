export const patchRoutes = {
  EDIT_SELF: '/users/:id/self',
  PASSWORD_BY_USER_ID: '/users/:id/password',
  UPDATE_STUDENT_PASSWORD: '/users/:id/student-password',
  BULK_UPDATE_STUDENT_PASSWORD: '/users/bulk-update-student-passwords',
  BULK_UPDATE_ALL_STUDENTS_PASSWORD:
    '/students/bulk-update-all-students-passwords',
  BEHAVIOR_CODE_ORDINALS_MASS_UPDATE: '/behavior-codes/ordinal/:schoolId',
  UPDATE_BELL_SCHEDULE_PERIOD_TIME: '/bell-schedule-period-times/:id',
  EDIT_ACTIVE_SCHOOL: '/auth/active-school',
  EDIT_ACTIVE_SCHOOL_GROUP: '/auth/active-school-group',
  CLEAR_SCHOOL_SETTINGS: '/auth/:id/clear-school-settings',
  EDIT_BELL_SCHEDULE: '/bell-schedules/:id',
  EDIT_INTERVENTION: '/interventions/:id',
  ASSIGN_GUARDIAN: '/students/:id/assign-guardian',
  EDIT_DAYS: '/reactions/:id/edit-days',
  DEACTIVATE_RECORDS: '/behavior-code-entries/deactivate-by-date',
  UPDATE_CUSTOM_ROSTER: '/custom-rosters/:id',
  UPDATE_OPTION: '/options/:id',
  UPDATE_INCIDENT: '/incidents/:id',
  UPDATE_REFERRAL: '/referrals/:id',
  UPDATE_DISPOSITIONS: '/referrals/dispositions/:id',
  UPDATE_HERO_DISPOSITION: '/referrals/hero-disposition/:id'
};
export type PatchRoutes = typeof patchRoutes;
