<div class="hero-disposition-container">
  <mat-form-field>
    <mat-label>Behavior</mat-label>
    <input disabled="true" matInput placeholder="Student" [value]="heroDisposition.behaviorCode.name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Action</mat-label>
    <input disabled="true" matInput placeholder="Student" [value]="heroDisposition.behaviorCodeReaction.name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Action Date</mat-label>
    <input disabled="true" matInput placeholder="Student" [value]="heroDisposition?.behaviorCodeEntry ? heroDisposition?.behaviorCodeEntry.name : 'no scheduled reaction'" />
  </mat-form-field>
</div>
